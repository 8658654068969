<template>
  <AppBar>
    <div class="top_box">
      <el-form :inline="true" label-width="130px">
        <el-form-item label="订单号：" prop="billNoEquals">
          <el-input
            placeholder="请输入订单号"
            clearable
            size="small"
            v-model="queryOrderParams.billNoEquals"
            oninput="value=value.replace(/[^\d]/g,'')"
          />
        </el-form-item>
        <el-form-item label="油站代码：" prop="storeCodeEquals" v-if="!totalCodeFlag">
          <el-select v-model="queryOrderParams.storeCodeEquals" placeholder="请选择" clearable size="small">
            <el-option
              v-for="item in oilList"
              :key="item.code"
              :label="item.name"
              :value="item.code">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单状态：" prop="status">
          <el-select
            size="small"
            clearable
            v-model="queryOrderParams.status"
            placeholder="请选择"
          >
            <el-option
              v-for="item in statusList"
              :key="item.state"
              :label="item.name"
              :value="item.state"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单起始时间：" prop="beginDate">
          <el-date-picker
            size="small"
            clearable
            v-model="dateList"
            type="daterange"
            range-separator="至"
            value-format="yyyy-MM-dd"
            @change="changeDate"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            style="margin-left: 20px"
            @click="handleQuery"
            >搜索</el-button
          >
          <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="body_box">
      <el-row :gutter="10" class="mb8">
        <el-col :span="1.5">
          <el-button
            type="primary"
            icon="el-icon-download"
            size="mini"
            @click="orderExport"
          >
            导出订单
          </el-button>
        </el-col>
      </el-row>
      <el-table :data="orderList">
        <el-table-column label="订单号" width="140" align="center" prop="billNo" />
        <el-table-column
          label="会员手机号"
          width="120"
          align="center"
          prop="mobile"
        />
        <el-table-column label="油品编号" align="center" prop="itemCode" />
        <el-table-column label="油品名称" align="center" width="150" prop="itemName" />
        <el-table-column label="油品售价" align="center" prop="salePrice" />
        <el-table-column label="油站名称" align="center"  width="220" prop="storeName" />
        <el-table-column label="油站编号" align="center" width="130" prop="storeCode" />
        <el-table-column label="状态" align="center">
          <template #default="{ row }">
            {{ ztfun(row) }}
          </template>
        </el-table-column>
        <el-table-column label="总金额" align="center" prop="payTotal" />
        <el-table-column label="实际支付金额" align="center" width="130" prop="realPay" />
        <el-table-column label="劵优惠金额" align="center" width="100" prop="couponPay" />
        <el-table-column label="会员直降优惠" width="130" align="center" prop="epsDiscount" />
        <el-table-column label="创建时间" align="center" prop="creatTime"  width="170"/>
        <el-table-column label="支付完成时间" align="center" prop="finishTime" width="170"/>
      </el-table>
      <pagination
        :total="total"
        :page.sync="queryOrderParams.pageNumber"
        :limit.sync="queryOrderParams.pageSize"
        @pagination="getMore"
        style="display: flex;align-items: center;justify-content: center;"
      />
    </div>
  </AppBar>
</template>

<style lang="less" scoped>
.content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: white;
  > i {
    width: 360px;
  }
  .box {
    position: relative;
    width: 360px;
    height: 170px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;

    .main {
      position: relative;
      width: 86%;
      height: 80%;
      display: flex;
      flex-direction: row;
      align-items: center;

      .one {
        position: relative;
        height: 100%;
        width: 36%;

        .el-image {
          height: 100%;
          width: 100%;
          border-radius: 4px;
        }
      }
      .two {
        margin-left: 20px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        > p {
          color: #afafaf;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          max-width: 120px;

          > span {
            color: #696c70;
          }
        }
      }
    }
  }
}
</style>

<script>
import AppBar from "@/components/AppBar";
import pagination from "@/components/Pagination";
import Api from "@/api/index";
export default {
  data() {
    return {
      currentPage: 0,
      total: 0,
      queryOrderParams: {
        pageNumber: 0,
        pageSize: 10
      },
      dateList: [],
      orderList: [],
      statusList: [
        {
          state: "create",
          name: "待支付"
        },
        {
          state: "paidfailed",
          name: "支付失败"
        },
        {
          state: "finished",
          name: "支付完成"
        },
        {
          state: "cancled",
          name: "已取消"
        }
      ],
      totalCodeFlag: false,
      userCode: JSON.parse(sessionStorage.getItem("loginMsg")),
      oilList: []
    };
  },
  methods: {
    async getStoresByOrgUuid() {
      await Api.getAllStoresByOrgUuid("6515f589866a11e994a2506b4b416ad4").then(res => {
        if (res && res.data) {
          this.oilList = res.data.data;
        }
      })
    },
    ztfun(row) {
      let text = "";
      this.statusList.forEach(item => {
        if (item.state === row.status) {
          text = item.name;
        }
      });
      return text;
    },
    // 分页
    getMore(pagination) {
      if (pagination.page === this.queryOrderParams.pageNumber) {
        this.queryOrderParams.pageSize = pagination.limit;
      } else {
        this.queryOrderParams.pageNumber = pagination.page;
      }
      this.queryList();
    },
    changeDate(e) {
      this.queryOrderParams.beginDate = e ? e[0] : "";
      this.queryOrderParams.endDate = e ? e[1] : "";
    },
    handleQuery() {
      console.log(this.queryOrderParams)
      this.queryOrderParams.pageNumber = 0;
      this.queryOrderParams.pageSize = 10;
      this.queryList();
    },
    resetQuery() {
      this.queryOrderParams = {
        pageNumber: 0,
        pageSize: 10
      };
      this.dateList = [];
      this.$forceUpdate();
      this.queryList();
    },
    cancel() {},
    orderExport() {
      // let kList = Object.keys(this.queryOrderParams);
      // params
      Api.exportOrder(this.queryOrderParams).then(res => {
        // console.log(res, "sdfdsfsfdfd");
        // var reader = new FileReader();
        console.log(res, "sdfsdffdwee");
        // reader.readAsDataURL(res.data);
        var blob = new Blob([res.data]);
        var a = document.createElement("a");
        // var d = new Date();
        // var d1 = d.getFullYear().toString();
        // var d2 = d.getMonth().toString();
        // var d3 = d.getDate().toString();
        // var d4 = d.getHours().toString();
        // var d5 = d.getMinutes().toString();
        // var d6 = d.getSeconds().toString();
        // var dd = d1 + d2 + d3 + d4 + d5 + d6;
        // console.log(dd);
        a.download = new Date().getTime() + ".xlsx";
        a.href = window.URL.createObjectURL(blob);
        // $("body").append(a); // 修复firefox中无法触发click
        // $(a).remove();
        a.style.display = "none"; //隐藏a标签 直接调用a标签的点击事件
        document.body.appendChild(a); // 修复firefox中无法触发click
        a.click();
        document.body.removeChild(a); // 修复firefox中无法触发click
        // reader.onload = function(e) {
        //   var a = document.createElement("a");
        //   var d = new Date();
        //   var d1 = d.getFullYear().toString();
        //   var d2 = d.getMonth().toString();
        //   var d3 = d.getDate().toString();
        //   var d4 = d.getHours().toString();
        //   var d5 = d.getMinutes().toString();
        //   var d6 = d.getSeconds().toString();
        //   var dd = d1 + d2 + d3 + d4 + d5 + d6;
        //   console.log(dd);
        //   a.downloadName = "用户模板.xlsx";
        //   a.href = e.target.result;
        //   // $("body").append(a); // 修复firefox中无法触发click
        //   // $(a).remove();
        //   a.style.display = "none"; //隐藏a标签 直接调用a标签的点击事件
        //   document.getElementByTagName("body").append(a); // 修复firefox中无法触发click
        //   a.click();
        //   document.getElementByTagName("body").removeChild(a); // 修复firefox中无法触发click
        // };
      });
    },
    // 获取列表数据
    queryList() {
      if(this.totalCodeFlag) {
        this.queryOrderParams.storeCodeEquals = this.userCode.user_code;
      }
      Api.queryOrder(this.queryOrderParams)
        .then(res => {
          console.log(res);
          this.orderList = res.data.data.records;
          this.total = res.data.data.paging.pageCount;
          this.currentPage = res.data.data.paging.pageSize;
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  async mounted() {
    this.userCode = JSON.parse(sessionStorage.getItem("loginMsg"));
    if(this.userCode && this.userCode.user_code && this.codeList.indexOf(this.userCode.user_code) > -1) {
      this.totalCodeFlag = true;
    }
    this.queryList();
    await this.getStoresByOrgUuid();
  },
  components: {
    AppBar,
    pagination
  }
};
</script>
<style>
.mb8 {
  margin-bottom: 20px;
}
</style>
